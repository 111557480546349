#intro {

  width: 100%;
  height: 100vh;
  background: url(../../assets/intro-bg.jpeg) top center;
  background-size: cover;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 767px) {
    height: calc(105vh - 85px);
  }
}

#intro:before {

  content: "";
  background: rgba(6, 12, 34, 0.8);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#intro .intro-container {

  position: absolute;
  bottom: 0;
  left: 0;
  top: 10px;
  right: 0;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

#intro h1 {

  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 56px;
  font-weight: 600;
  text-transform: uppercase;
}

#intro h1 span {

  color: #6704fb;
}

#intro p {

  color: #ebebeb;
  font-weight: 700;
  font-size: 20px;
}

#intro .play-btn {

  width: 94px;
  height: 94px;
  background: radial-gradient(#6704fb 50%, rgba(101, 111, 150, 0.15) 52%);
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
}

#intro .play-btn::after {

  content: "";
  position: relative;
  left: 50%;
  top: 50%;

  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);

  width: 0;
  height: 0;

  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;

  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

#intro .play-btn:before {

  content: "";
  position: absolute;
  width: 120px;
  height: 120px;

  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;

  opacity: 1;
  border-radius: 50%;
  border: 2px solid rgba(163, 163, 163, 0.4);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

#intro .play-btn:hover::after {

  border-left: 15px solid #6704fb;
  -webkit-transform: scale(20);
  transform: scale(20);
}

#intro .play-btn:hover::before {

  content: "";
  position: absolute;
  left: 50%;
  top: 50%;

  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);

  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;

  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

#intro .about-btn {

  bottom: 30px;

  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;

  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #6704fb;
  position: absolute;
}

#intro .about-btn:hover {

  background: #6704fb;
  color: #fff;
  position: absolute;
  text-decoration: none;
}

@-webkit-keyframes pulsate-btn {

  0% {

    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {

    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {

  0% {

    -webkit-transform: scale(0.6, 0.6);
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {

    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Responsive Media Queries
--------------------------------------------------------------*/

#intro /* Styles for the card */
.card {
  perspective: 1000px;
  width: 252px; /* Adjust width to make it thinner if needed */
  height: 397px; /* Adjust height if needed */
  border-radius: 11px;
  overflow: hidden;
  position: relative;
  margin: 0; /* Ensure no margin around the card */
  border: none; /* Remove any default border */
  box-shadow: 0 1px 5px #00000099; /* Optional shadow */
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  background-size: contain; /* Ensure the image fits within the card */
  background-position: center;
}

.card-front {
  background-image: url("../../assets/card.png");
}

.card-back {
  background-image: url("../../assets/card-back.png");
  transform: rotateY(180deg);
}


/* Flip effect */
.card.flipped .card-front {
  transform: rotateY(180deg);
}

.card.flipped .card-back {
  transform: rotateY(0deg);
}




.card:hover {

  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.card .glow {

  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.card .userinfo {

  color: #fff;
  font-weight: bold;
  position: relative;
  text-align: left;
  top: 16em;
  left: 1.28em;

}
.map-container {

    width: 100%;
    aspect-ratio: 2/1;
    border-radius: 2em;
    overflow: hidden;
    margin: 0 auto;
    @media screen and (max-width: 720px) {
        aspect-ratio: 1;
    }
}

.filter-container {

    width: calc(4% + 200px);
    height: 40px;
    margin: 30px 0 20px calc(85% - 120px);
    display: flex;
    justify-content: space-around;
    overflow-x: visible;
    overflow-y: visible;
    
    @media screen and (max-width: 680px) {
      
      margin: 30px auto 20px;
    }
  }
  
  .filter-btn {
  
    width: 100%;
    height: 40px;
    display: flex;
    align-items:center;
    justify-content:center;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    background-color: #ddd;
    transition: all 0.4s ease;
  }
  
  .filter-container .filter-dropdown {
    
    width: 48%;
    z-index: 2;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: all 1s ease;
  }
  
  .filter-btn:hover {
  
    background-color: #eee;
    cursor: pointer;
  }
  
  .selected {
  
    background-color: #fff;
  }

  .selected-filter {

    background-color: #f5f2f6;
  }
  
  .filter-container .dropdown-opened {
  
    height: 200px;
  }
  
  .filter-container .dropdown-closed {
  
    height: 40px;
  }
#header {

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  overflow-y: hidden;

  @media screen and (max-width: 990px) {

    padding: 12px 0;
    background-color: rgb(6, 12, 34);
    height: 70px;
  }
}

#header {

  transition: all 0.5s;
}

.opaque-header {

  background: rgba(6, 12, 34, 0.98);
  height: 70px;
  padding: 15px 0;
}

#logo {

  float: left;

  @media screen and (max-width: 990px) {

    position: fixed;
    left: 50%;
    top: 13px;
    transform: translateX(-50%);
  }
}

#header #logo h1 {

  font-size: 36px;
  margin: 0;
  padding: 6px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 span {

  color: #6704fb;
}

#header #logo h1 a,
#header #logo h1 a:hover {

  color: #fff;
}

#header #logo img {

  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Nav Menu Essentials */

.nav-menu,
.nav-menu * {

  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu li {

  position: relative;
  white-space: nowrap;
}

.nav-menu>li {

  float: left;
}

.nav-menu li:hover>ul,
.nav-menu li.sfHover>ul {

  display: block;
}

.nav-menu li a:hover {

  text-decoration: none;
}

/* Nav Meu Container */

#nav-menu-container {

  float: right;
  margin: 0;
  position: relative;
  width: fit-content;

  @media screen and (max-width : 990px) {

    display: none;
    overflow: hidden;
  }
}

.nav-menu a {

  padding: 8px;
  text-decoration: none;
  display: inline-block;
  color: rgba(202, 206, 221, 0.8);
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 14px;
  outline: none;
}

.nav-menu a:hover {

  color: #fff;
}

.nav-menu>li {

  margin-left: 8px;
}

@media screen and (min-width: 991px) {

  .nav-menu>li>a:before {

    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #6704fb;
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
  }
}

@media screen and (min-width: 991px) {

  .nav-menu a:hover:before,
  .nav-menu li:hover>a:before,
  .nav-menu .menu-active>a:before {

    visibility: visible;
    width: 100%;
  }
}

.nav-menu {

  @media screen and (max-width: 990px) {

    display: flex !important;
    flex-direction: column !important;
  }
}

.nav-menu li a {

  @media screen and (max-width: 990px) {

    color: #fff;
  }
}

.nav-menu li:hover>a {

  @media screen and (max-width: 990px) {

    background: transparent;
    color: #6704fb;
  }
}

.sticky {

  padding: 12px 0;
  background-color: rgb(6, 12, 34);
  height: 70px;
}

.not-sticky {

  padding: 25px 0;
  height: 90px;
}
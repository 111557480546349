.tab-content {

  aspect-ratio: 4/3;
  width: 100%;
  height: auto;
}

.tabpanel {

  width: 100%;
}

#schedule .item-title {

  border-bottom: 1px solid #cad4f6;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color ease-in-out 0.3s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#schedule .item-title:hover {

  background-color: #fff;
}

#schedule .item-title h4 {

  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

#schedule .item-title h4 span {

  font-style: italic;
  color: #19328e;
  font-weight: normal;
  font-size: 16px;
}

#schedule .item-title p {

  font-style: italic;
  color: #152b79;
  margin-bottom: 0;
}

.filter-container {

  width: calc(4% + 200px);
  height: 40px;
  margin: 30px 0 20px calc(85% - 120px);
  display: flex;
  justify-content: space-around;
  overflow-x: visible;
  overflow-y: visible;

  @media screen and (max-width: 680px) {

    margin: 30px auto 20px;
  }
}

.filter-btn {

  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  background-color: #ddd;
  transition: all 0.4s ease;
}

.filter-container .filter-dropdown {

  width: 48%;
  z-index: 2;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 1s ease;
}

.filter-btn:hover {

  background-color: #eee;
  cursor: pointer;
}

.selected {

  background-color: #fff;
}

.selected-filter {

  background-color: #efefef;
}

#schedule .filter-container .dropdown-opened {

  height: 240px;
}

.filter-container .dropdown-closed {

  height: 40px;
}

.dropdown-btn {

  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", sans-serif;
  background-color: #ddd;
  transition: all 0.3s ease;
}

.dropdown-btn:hover {

  background-color: #eee;
  cursor: pointer;
}

.discount-circle {

  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  background-color: grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f7fd;
}

.dropdown-arrow {

  margin-right: 5%;
}


.dropdown-map {

  overflow: hidden;
  transition: all 0.4s ease;
  width: 100%;
  height: 0;
}

.dropdown-active {

  height: 600px;

  @media screen and (max-width: 1080px) {

    height: 480px;
  }

  @media screen and (max-width: 720px) {

    height: 360px;
  }

  @media screen and (max-width: 480px) {

    height: 280px;
  }
}

.dropdown-not-active {

  height: 0;
}

.dropdown-map .map-container {

  width: 80%;
  aspect-ratio: 3/2;
  border-radius: 2em;
  margin-top: 30px;
  border: 5px solid #cad4f6;
}
#footer {

  background: #101522;
  padding: 0 0 25px 0;
  color: #eee;
  font-size: 14px;
  overflow: hidden;
}

#footer .footer-top {

  background: #040919;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {

  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {

  font-size: 26px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #fff;
}

#footer .footer-top .footer-info img {

  height: 40px;
  margin-bottom: 10px;
}

#footer .footer-top .footer-info p {

  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {

  font-size: 18px;
  display: inline-block;
  background: #222636;
  color: #eee;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {

  background: #6704fb;
  color: #fff;
}

#footer .footer-top h4 {

  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  border-bottom: 2px solid #6704fb;
}

#footer .footer-top .footer-links {

  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {

  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {

  padding-right: 5px;
  color: #6704fb;
  font-size: 18px;
}

#footer .footer-top .footer-links ul li {

  border-bottom: 1px solid #262c44;
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {

  padding-top: 0;
}

#footer .footer-top .footer-links ul a {

  color: #eee;
}

#footer .footer-top .footer-links ul a:hover {

  color: #6704fb;
}

#footer .footer-top .footer-contact {

  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {

  line-height: 26px;
}

#footer .footer-top .footer-newsletter {

  margin-bottom: 30px;
}

#footer .footer-top .footer-newsletter input[type="email"] {

  border: 0;
  padding: 6px 8px;
  width: 65%;
}

#footer .footer-top .footer-newsletter input[type="submit"] {

  background: #6704fb;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

#footer .footer-top .footer-newsletter input[type="submit"]:hover {

  background: #6704fb;
}

#footer .copyright {

  text-align: center;
  padding-top: 30px;
}

#footer .credits {

  text-align: center;
  font-size: 13px;
  color: #ddd;
}
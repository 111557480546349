.back-to-top {

    position: fixed;
    background: #6704fb;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50px;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s ease-in-out, opacity 1s ease;
    font-size: x-large;
}

.back-to-top:hover {

    background: #5603ea;
    color: #eee;
    cursor: pointer;
}

.active {

    opacity: 100%;
}

.not-active {

    opacity: 0%;
}
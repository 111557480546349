#faq {

    padding: 60px 0;
    overflow: hidden;
}

#faq #faq-list {

    padding: 0;
    list-style: none;
}

#faq #faq-list li {

    border-bottom: 1px solid #ddd;
}

#faq #faq-list a {

    padding: 18px 0;
    display: block;
    position: relative;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    padding-right: 20px;
}

#faq #faq-list i {

    font-size: 24px;
    position: absolute;
    right: 0;
    top: 16px;
}

#faq #faq-list p {

    margin-bottom: 20px;
}

#faq #faq-list a.collapse {

    color: #6704fb;
}

#faq #faq-list a.collapsed {

    color: #000;
}

#faq #faq-list a.collapsed i::before {

    content: "\f055" !important;
}
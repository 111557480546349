.sponsor-map-section {
    width: 100%;
    background-color: #f6f7fd;
    margin: 0 auto;
    padding: calc(2% + 2px) 0;
    overflow: hidden;
}

.sponsor-map-section .section-header {

    @media screen and (max-width: 350px) {

        transform: scale(0.7, 0.7);
    }
}

.selection-container {

    width: calc(5% + 150px);
    height: 40px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
}

.selection {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    background-color: #ddd;
    transition: all 0.4s ease;
}

.selection:hover {

    background-color: #eee;
    cursor: pointer;
}

.selected {
    background-color: #fff;
}

.screen {

    width: calc(70% + 100px);
    margin: 0 auto;
    margin-top: 20px;

}
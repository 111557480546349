/*
  Theme Name: TheEvent
  Theme URL: https://bootstrapmade.com/theevent-conference-event-bootstrap-template/
  Author: BootstrapMade.com
  License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  
  background: #fff;
  color: #2f3138;
  font-family: "Open Sans", sans-serif;
}

a {

  color: #6704fb;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {

  color: #6704fb;
  outline: none;
  text-decoration: none;
}

p {

  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {

  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
  color: #0e1b4d;
}

.main-page {

  margin-top: 70px;
}

.wow {

  visibility: hidden;
}

/* Sections Header
--------------------------------*/

.section-header {

  margin-bottom: 60px;
  position: relative;
  padding-bottom: 20px;
}

.section-header::before {

  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 5px;
  background: #6704fb;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-header h2 {

  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.section-header p {

  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #9195a2;
}

.section-with-bg {
  
  background-color: #f6f7fd;
}